const internalPaths = [
  '/admin',
  '/signup',
  '/login',
  '/settings',
  '/account',
  '/checkout',
  '/c/',
  '/overlay',
  '/s/',
  '/subscriptions',
  '/submissions',
  '/pages',
  '/checkouts',
  '/customers',
  '/coupons',
  '/seller',
  '/orders',
  '/payments',
  '/logout',
];

function parseTo(href) {
  const parser = document.createElement('a');
  parser.href = href;
  return parser;
}

export default function isInternalLink(href) {
  const toLocation = parseTo(href);

  if (window.location.host !== toLocation.host) {
    return false;
  }

  return internalPaths.some(path => toLocation.pathname.startsWith(path));
}
